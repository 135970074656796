<template>
  <b-container class="report-account-plan-2 bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <h1 class="text-left">{{ $t(translationPath + 'title_assets') }}</h1>
      </b-row>
      <account-plan-submenu :active="currentActiveTab"></account-plan-submenu>

      <div class="wrapper_white_with_border py-4">

        <account-plan-assets-header v-if="$route.name === 'account-plan-assets-values'"></account-plan-assets-header>
        <account-plan-liabilities-header v-if="$route.name === 'account-plan-liabilities-values'"></account-plan-liabilities-header>

        <b-card no-body class="white-tabs-card">
          <b-tabs card class="white-tabs"  @activate-tab="onTabActivate">
            <AssetsMenu v-if="$route.name === 'account-plan-assets-values'">
              <template slot="account-plan-assets-values">
                <!-- START TABLE -->
                <b-row no-gutters>
                  <b-col cols="8">
                    <div class="text-regular" v-html="$t(translationPath + 'description_asset_values')"></div>
                  </b-col>
                </b-row>

                <!-- START TABLE -->
                <b-table class="spirecta-simple-table report-account-plan-2-value-table" show-empty hover responsive striped
                         :items="tableData"
                         :fields="fieldValues"
                         :emptyText="$t(translationPath + 'no_data')"
                         :filter="accountsFilter"
                         :filter-included-fields="filterOn"
                         :emptyFilteredText ="$t('common.no_filter_result')"
                         stacked="md"
                         :tbody-tr-class="trClass"
                         :busy="busy"
                >
                  <!-- LOADER -->
                  <template v-slot:table-busy>
                    <loader />
                  </template>

                  <!-- CUSTOM SLOTS -->

                  <!-- CUSTOM SLOT: FILTER -->
                  <template slot="top-row">
                    <td colspan="5">
                      <div class="d-flex align-items-center">
                        <i class="fa fa-search text-secondary"></i>
                        <b-form-input v-model="accountsFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                      </div>
                    </td>
                  </template>

                  <!-- CUSTOM SLOT: TITLE -->
                  <template v-slot:cell(title)="row">
                    <template v-if="row.item.is_account === true">
                      <span class="px-2 account_title">&ndash;</span>
                        <b-link  :to="'/reports/performance/accounts/' + row.item.id + '/view'"  class="account_title" >
                          {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                        </b-link>
                    </template>
                    <template v-else-if="row.item.is_account_group === true">
                      <b-link  :to="'/reports/performance/account-groups/' + row.item.id"  class="account_group_title">
                        {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                      </b-link>
                    </template>
                    <template v-else-if="row.item.is_part_sum_row === true">
                      {{ $t('common.subtotal') }}
                    </template>
                    <template v-else-if="row.item.is_total_sum_row === true">
                      {{ $t('common.total') }}
                    </template>
                  </template>

                  <!-- CUSTOM SLOT: AMOUNTS -->
                  <template v-slot:cell(amount)="row">
                    <template v-if="row.item.is_account === true">
                      {{ row.value }}
                    </template>
                    <template v-else-if="row.item.is_part_sum_row === true">
                      {{ row.value }}
                    </template>
                    <template v-else-if="row.item.is_total_sum_row === true">
                      {{ row.value }}
                    </template>
                  </template>

                  <!-- CUSTOM SLOT: ACTIONS -->
                  <!-- CUSTOM SLOT: ACTIONS -->
                  <template v-slot:cell(actions)="row">
                    <template v-if="row.item.is_account === true">
                      <b-link  v-if="row.item.allow_update || !row.item.latest_transaction_date"  class="btn plain-btn text-regular action-button"  :to="'/assets-liabilities/update-value/asset/' + row.item.id">
                        <i class="flaticon solid text-secondary" :class="row.item.latest_transaction_date ? 'refresh' : 'money'"></i>
                        <span>{{ $t('reports.other.account_plan.' + (row.item.latest_transaction_date ? "update_value" : "set_starting_value")) }}</span>
                      </b-link>
                    </template>
                    <template v-else></template>
                  </template>

                </b-table>
                <!-- END TABLE -->

              </template>
            </AssetsMenu>
            <LiabilityMenu v-if="$route.name === 'account-plan-liabilities-values'">
              <template slot="account-plan-liabilities-values">
                <b-row no-gutters>
                  <b-col cols="8">
                    <div class="text-regular" v-html="$t(translationPath + 'description_liability_values')"></div>
                  </b-col>
                </b-row>

                <b-table class="spirecta-simple-table report-account-plan-2-value-table" show-empty hover responsive striped
                         :items="tableData"
                         :fields="fieldValues"
                         :emptyText="$t(translationPath + 'no_data')"
                         :filter="accountsFilter"
                         :filter-included-fields="filterOn"
                         stacked="md"
                         :tbody-tr-class="trClass"
                         :emptyFilteredText ="$t('common.no_filter_result')"
                         :busy="busy"
                >
                  <!-- LOADER -->
                  <template v-slot:table-busy>
                    <loader />
                  </template>

                  <!-- CUSTOM SLOTS -->

                  <!-- CUSTOM SLOT: FILTER -->
                  <template slot="top-row">
                    <td colspan="5">
                      <div class="d-flex align-items-center">
                        <i class="fa fa-search text-secondary"></i>
                        <b-form-input v-model="accountsFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                      </div>
                    </td>
                  </template>

                  <!-- CUSTOM SLOT: TITLE -->
                  <template v-slot:cell(title)="row">
                    <template v-if="row.item.is_account === true">
                      <span class="px-2 account_title">&ndash;</span>
                      <b-link  :to="'/reports/performance/accounts/' + row.item.id + '/view'"  class="account_title" >
                        {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                      </b-link>
                    </template>
                    <template v-else-if="row.item.is_account_group === true">
                      <b-link  :to="'/reports/performance/account-groups/' + row.item.id"  class="account_group_title">
                        {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                      </b-link>
                    </template>
                    <template v-else-if="row.item.is_part_sum_row === true">
                      {{ $t('common.subtotal') }}
                    </template>
                    <template v-else-if="row.item.is_total_sum_row === true">
                      {{ $t('common.total') }}
                    </template>
                  </template>

                  <!-- CUSTOM SLOT: AMOUNTS -->
                  <template v-slot:cell(amount)="row">
                    <template v-if="row.item.is_account === true">
                      {{ row.value }}
                    </template>
                    <template v-else-if="row.item.is_part_sum_row === true">
                      {{ row.value }}
                    </template>
                    <template v-else-if="row.item.is_total_sum_row === true">
                      {{ row.value }}
                    </template>
                  </template>

                  <!-- CUSTOM SLOT: ACTIONS -->
                  <template v-slot:cell(actions)="row">
                    <template v-if="row.item.is_account === true">
                      <b-link  v-if="row.item.allow_update || !row.item.latest_transaction_date"  class="btn plain-btn text-regular action-button"  :to="'/assets-liabilities/update-value/liability/' + row.item.id">
                        <i class="flaticon solid text-secondary" :class="row.item.latest_transaction_date ? 'refresh' : 'money'"></i>
                        <span>{{ $t('reports.other.account_plan.' + (row.item.latest_transaction_date ? "update_value" : "set_starting_value")) }}</span>
                      </b-link>
                    </template>
                    <template v-else></template>
                  </template>

                </b-table>
                <!-- END TABLE -->

              </template>
            </LiabilityMenu>
          </b-tabs>
        </b-card>

      </div><!-- END: wrapper_white_with_border -->
    </div><!-- END: main-content-wrapper -->

  </b-container>
</template>

<script>
import AccountPlanTabMixin from './AccountPlanTabMixin'
import AccountPlanActionsMixin from './AccountPlanActionsMixin'
import AssetsMenu from './submenu/AssetsMenu'
import LiabilityMenu from './submenu/LiabilityMenu'
import AccountPlanAssetsHeader from './headers/AccountPlanAssetsHeader'
import AccountPlanLiabilitiesHeader from './headers/AccountPlanLiabilitiesHeader'
import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'

export default {
  name: 'AccountPlanAssetsOverview',
  mixins: [AccountPlanTabMixin, AccountPlanActionsMixin],
  components: { LiabilityMenu, AssetsMenu, AccountPlanAssetsHeader, AccountPlanLiabilitiesHeader },
  filter: { formatAmount },
  data () {
    return {
      accountsFilter: '',
      filterOn: ['title'],
      tableData: [],
      currentActiveTab: 'assets',
      accountType: 'asset'
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    fieldValues () {
      return [
        { key: 'title', label: this.$t(this.translationPath + 'table_title_' + this.currentActiveTab), sortable: false },
        { key: 'latest_transaction_date', label: this.$t(this.translationPath + 'last_value_date'), sortable: false, class: 'td-date text-left text-md-right dateRow' },
        { key: 'amount', label: this.$t('assets_liabilities.common.latest_value'), sortable: false, class: 'text-right', formatter: this.amountFormatter },
        { key: 'actions', label: this.$t(this.translationPath + 'action'), class: 'text-right' }
      ]
    }
  },
  methods: {
    amountFormatter (value) {
      return formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0)
    },
    async loadData () {
      if (!this.currentCOA.locale) {
        return true
      }
      if (this.$route.name === 'account-plan-assets-values') {
        this.currentActiveTab = 'assets'
        this.accountType = 'asset'
      } else if (this.$route.name === 'account-plan-liabilities-values') {
        this.currentActiveTab = 'liabilities'
        this.accountType = 'liability'
      }

      this.busy = true
      Promise.all([
        this.loadAccountGroupsWithData(this.accountType),
        this.loadAccountGroups(this.accountType)
      ])
        .then(() => {
          let bFirst = true
          const oEmptyRow = { is_total_sum_row: false, is_part_sum_row: false, is_empty_row: true, is_account: false, is_account_group: false, title: '', date: '', amount: '', actions: '' }
          const oPartSumRow = { is_total_sum_row: false, is_part_sum_row: true, is_empty_row: false, is_account: false, is_account_group: false, title: '', date: '', amount: '', actions: '' }
          const oTotalSumRow = { is_total_sum_row: true, is_empty_row: false, is_account: false, is_account_group: false, title: '', date: '', amount: '', actions: '' }
          let iPartSum = 0
          let iTotalSum = 0

          const aReportAccounts = this.accountsGrouped[this.accountType]

          for (const index in aReportAccounts) {
            // Add empty row before account groups except for first row.
            if (aReportAccounts[index].is_account_group === true) {
              if (bFirst) {
                bFirst = false
              } else {
                oPartSumRow.amount = iPartSum

                this.tableData.push({ ...oPartSumRow })
                this.tableData.push(oEmptyRow)
                iPartSum = 0
              }
            }
            if (aReportAccounts[index].is_account === true) {
              iPartSum = iPartSum + aReportAccounts[index].amount_unformatted
              iTotalSum = iTotalSum + aReportAccounts[index].amount_unformatted
            }
            this.tableData.push(aReportAccounts[index])
          }

          // Last group
          oPartSumRow.amount = iPartSum
          this.tableData.push({ ...oPartSumRow })
          this.tableData.push(oEmptyRow)

          // Add total row
          oTotalSumRow.amount = iTotalSum
          this.tableData.push(oTotalSumRow)

          this.busy = false
        })
    },
    trClass (item) {
      if (item && item.is_account_group === true) {
        return 'tr-account-group'
      } else if (item && item.is_account === true) {
        return 'tr-account'
      } else if (item && item.is_empty_row === true) {
        return 'bg-white'
      } else if (item && item.is_total_sum_row === true) {
        return 'total-sum'
      } else if (item && item.is_part_sum_row === true) {
        return 'part-sum'
      }
      return ''
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    }
  }
}
</script>

<style lang="scss">
@import './AccountPlan.scss';

.report-account-plan-2-value-table{
}

</style>
